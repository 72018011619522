import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import TopRiders from "./components/TopRiders";
import Events from "./components/Events";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <BrowserRouter>
        <Routes>
            <Route exact path="/" element={<App />} />
            <Route exact path="/colorado-riders" element={<TopRiders />} />
            <Route exact path="/mountainboard-events" element={<Events />} />
        </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
