import React, { useState } from "react";
import TopNav from "./TopNav";
import Rider from "./Rider";
import SearchBox from "./RiderSeaarchBox";
import ridersList from "./data/ridersList.json";
import SideNav from "./SideNav";
import "./style/riders.css";

function TopRiders() {
    const [riders, setRiders] = useState(ridersList.riders);
    const [wid, setWid] = useState("0px");
    const [filterBy, setFilterBy] = useState("");
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const openSidenav = () => {
        setWid("340px");
        setMenuIsOpen(true);
    };

    const closeSidenav = () => {
        setWid("0%");
        setMenuIsOpen(false);
    };

    const onSearchChange = (event) => {
        const searchField = event.target.value.toLocaleLowerCase();
        setFilterBy(searchField);
    };

    const filteredRiders = riders.filter((rider) => {
        return rider.name.toLocaleLowerCase().includes(filterBy);
    });

    return (
        <div>
            <TopNav
                menuIsOpen={menuIsOpen}
                setMenuIsOpen={setMenuIsOpen}
                openSidenav={openSidenav}
            />
            <div className="rider-container">
                <SearchBox onChangeHandler={onSearchChange} />
                <div className="top-riders">
                    {filteredRiders.map((rider) => {
                        return <Rider rider={rider} key={rider.id} />;
                    })}
                </div>
            </div>
            <SideNav
                width={wid}
                closeNav={closeSidenav}
                setMenuIsOpen={setMenuIsOpen}
            />
        </div>
    );
}

export default TopRiders;
