import React from "react";
import "./master.css";
import { Button } from "react-bootstrap";

const TopNav = ({ menuIsOpen, setMenuIsOpen, openSidenav }) => {
    return (
        <div className="top-nav">
            <div className="top-nav-title">Surfin' Earth</div>
            {!menuIsOpen && (
                <Button
                    className="menu-open"
                    onClick={() => {
                        setMenuIsOpen(true);
                        openSidenav();
                    }}
                >
                    MENU
                </Button>
            )}
        </div>
    );
};

export default TopNav;
