import React from "react";
import Image from "react-bootstrap/Image";
import { Button } from "react-bootstrap";

const Rider = ({ rider }) => {
    return (
        <div className="rider-div">
            <div className="rider-info">
                <Image src={rider.img} className="rider-image" />
                <div className="rider-text">
                    <h2>Rider Name:</h2>
                    <h4>{rider.name}</h4>
                </div>
            </div>
            <div className="social-buttons">
                {rider.ytLink && (
                    <div>
                        <Button
                            className="youtube-link"
                            onClick={() =>
                                window.open(
                                    `${rider.ytLink}`,
                                    "_blank",
                                    "noopener,noreferrer"
                                )
                            }
                        >
                            {/* <Image
                                className="yt-logo"
                                src="/social-icons/yt-logo.png"
                            /> */}
                            YouTube
                        </Button>
                    </div>
                )}
                <br />
                {rider.instaLink && (
                    <Button
                        className="instagram-link"
                        onClick={() =>
                            window.open(
                                `${rider.instaLink}`,
                                "_blank",
                                "noopener,noreferrer"
                            )
                        }
                    >
                        Instagram
                    </Button>
                )}
                <br />
                {rider.tikLink && (
                    <Button
                        className="tiktok-link"
                        onClick={() =>
                            window.open(
                                `${rider.tikLink}`,
                                "_blank",
                                "noopener,noreferrer"
                            )
                        }
                    >
                        TikTok
                    </Button>
                )}
            </div>
        </div>
    );
};

export default Rider;
