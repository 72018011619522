import React, { useState } from "react";
import Button from "react-bootstrap/Button";

const VideoSlider = ({ mediaList }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === mediaList.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handlePreviousClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? mediaList.length - 1 : prevIndex - 1
        );
    };

    const renderedMedia = mediaList[currentIndex];

    return (
        <div className="video-slider">
            <div className="video-title">
                <h1>{renderedMedia.title}</h1>
            </div>
            <div className="video-and-nav">
                <Button
                    className="nav-arrow prev"
                    onClick={handlePreviousClick}
                >
                    {"<"}
                </Button>
                <div className="media-container">
                    <iframe
                        src={renderedMedia.src}
                        alt={renderedMedia.alt}
                    ></iframe>
                </div>
                <Button className="nav-arrow next" onClick={handleNextClick}>
                    {">"}
                </Button>
            </div>
            <div className="video-description">
                <p> {renderedMedia.description} </p>
            </div>
        </div>
    );
};

export default VideoSlider;
