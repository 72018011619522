import React from "react";
import "./master.css";
import { Button } from "react-bootstrap";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

const SideNav = ({ width, closeNav, setMenuIsOpen }) => {
    return (
        <div className="side-nav" style={{ width: width }}>
            <div style={{ height: "100px" }}> &nbsp </div>
            <Button
                className="close-menu-button"
                onClick={() => {
                    closeNav();
                    setMenuIsOpen(false);
                }}
            >
                X
            </Button>
            <Link to="/" className="menu-item">
                TOP MOUNTAINBOARDING VIDEOS
            </Link>
            <Link to="/colorado-riders" className="menu-item">
                COLORADO RIDERS
            </Link>
            <a
                className="menu-item"
                href="https://www.wetheriders.org/"
                target="_blank"
                rel="noopener noreferrer"
            >
                MOUNTAINBOARD EVENTS USA
            </a>
        </div>
    );
};

export default SideNav;
