import React, { useState } from "react";
import "./master.css";
import { Button } from "react-bootstrap";

const Event = ({ event }) => {
    const [startDate, setStartDate] = useState(new Date(event.startDate));
    const [endDate, setEndDate] = useState(new Date(event.endDate));

    const formatteStartdDate = new Date(startDate).toLocaleDateString("en-US", {
        // weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        // hour: "numeric",
        // minute: "numeric",
        // second: "numeric",
        // timeZoneName: "short",
    });

    const formattedEndDate = new Date(endDate).toLocaleDateString("en-US", {
        // weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        // hour: "numeric",
        // minute: "numeric",
        // second: "numeric",
        // timeZoneName: "short",
    });

    return (
        <div className="event-div">
            <div className="event-info">
                {event.name && (
                    <div className="event-text">
                        <h2>Event Name:</h2>
                        <h4>{event.name}</h4>
                    </div>
                )}
                {(startDate || endDate) && (
                    <div>
                        <div className="event-dates">
                            Start Date:
                            {startDate && <Button>{formatteStartdDate}</Button>}
                            <br />
                            End Date:
                            {endDate && <Button>{formattedEndDate}</Button>}
                        </div>
                    </div>
                )}
                {event.location && (
                    <div className="event-location">
                        Location:
                        <Button>{event.location}</Button>
                    </div>
                )}
                {event.description && (
                    <div className="event-description">
                        About:
                        <Button>{event.description}</Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Event;
