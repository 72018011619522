import React from "react";
import "./master.css";

const SearchBox = (props) => {
    return (
        <div className="search-box-container">
            <input
                className={`rider-search-box`}
                type="search"
                placeholder={"Filter Riders"}
                onChange={props.onChangeHandler}
            />
        </div>
    );
};

export default SearchBox;
