import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import "./components/style/app.css";
import SideNav from "./components/SideNav";
import TopNav from "./components/TopNav";
import VideoSlider from "./components/VideoSlider";
import ShortVideoSlider from "./components/ShortVideoSlider";
import videosList from "./components/data/videosList.json";
import reelsList from "./components/data/reelsList.json";

function App(props, state) {
    const [wid, setWid] = useState("0px");
    const [toggle, setToggle] = useState(true);
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const openSidenav = () => {
        setWid("340px");
        setMenuIsOpen(true);
    };

    const closeSidenav = () => {
        setWid("0%");
        setMenuIsOpen(false);
    };

    const [mediaList, setMediaList] = useState(videosList.videos);
    const [shortMediaList, setShortMediaList] = useState(reelsList.reels);

    return (
        <div className="page">
            <header>
                <TopNav
                    menuIsOpen={menuIsOpen}
                    setMenuIsOpen={setMenuIsOpen}
                    openSidenav={openSidenav}
                />
            </header>
            <div className="container">
                <div style={{ height: "100px" }} />
                {toggle && <VideoSlider mediaList={mediaList} />}
                {!toggle && <ShortVideoSlider mediaList={shortMediaList} />}
                {toggle && (
                    <div className="toggle-buttons">
                        <Button className="btn-login">Videos</Button>
                        <Button
                            className="btn-signup"
                            onClick={() => setToggle(!toggle)}
                        >
                            Shorts/Reels
                        </Button>
                    </div>
                )}
                {!toggle && (
                    <div className="toggle-buttons">
                        <Button
                            className="btn-signup"
                            onClick={() => setToggle(!toggle)}
                        >
                            Videos
                        </Button>
                        <Button className="btn-login">Shorts/Reels</Button>
                    </div>
                )}
            </div>
            <SideNav
                width={wid}
                closeNav={closeSidenav}
                setMenuIsOpen={setMenuIsOpen}
            />
        </div>
    );
}

export default App;
