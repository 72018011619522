import React, { useState } from "react";
import TopNav from "./TopNav";
import Event from "./Event";
import eventsList from "./data/eventsList.json";
import SideNav from "./SideNav";
import "./style/events.css";

function Events() {
    const [events, setEvents] = useState(eventsList.events);
    const [wid, setWid] = useState("0px");
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const openSidenav = () => {
        setWid("340px");
        setMenuIsOpen(true);
    };

    const closeSidenav = () => {
        setWid("0%");
        setMenuIsOpen(false);
    };

    return (
        <div className="page">
            <TopNav
                menuIsOpen={menuIsOpen}
                setMenuIsOpen={setMenuIsOpen}
                openSidenav={openSidenav}
            />
            <div className="events-container">
                <div className="events">
                    {events.map((event) => {
                        return <Event event={event} key={event.id} />;
                    })}
                </div>
            </div>
            <SideNav
                width={wid}
                closeNav={closeSidenav}
                setMenuIsOpen={setMenuIsOpen}
            />
        </div>
    );
}

export default Events;
